@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
    font-family: "Urbanist";
    src: local("Urbanist-Regular"),
        url("./assets/Urbanist-Regular.ttf") format("truetype");
    font-weight: normal;
}

* {
    font-family: Urbanist, sans-serif;
}